@charset "UTF-8";

.c-v-modal {
  &-bg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    width: 0;
    height: 0;
    opacity: 0;
    background: $black;
    transition: opacity .4s ease .1s;

    &.is-active {
      width: 100%;
      height: 100%;
      opacity: 1;
    }
  }

  &-btn {
    @include fz(15);
    display: block;
    border: 0;
    text-align: left;
    background: transparent;
    outline: none;
    text-decoration: underline;
    cursor: pointer;
  }

  &-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 5%;
    max-width: calc(1200px + 10%);
    max-height: 100%;
    width: 100%;
  }

  &-close {
    //Xボタン
    position: absolute;
    z-index: 2;
    right: 5%;
    display: inline-block;
    width: 40px;
    height: 40px;
    transform: translateX(-50%);
    cursor: pointer;

    @include is-mobile {
      width: 30px;
      height: 30px;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      display: inline-block;
      width: 40px;
      height: 3px;
      background: $white;
      transform: rotate(45deg);

      @include is-mobile {
        width: 30px;
        height: 2px;
      }
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &-youtube {
    position: relative;
    width: 100%;
    max-height: 600px;
    height: calc(100vh - 50px);
    margin-top: 50px;
    // padding-top: 55%; // 高さ

    @include is-mobile {
      margin-top: 30px;
      max-height: 450px;
    }

    iframe {
      // position: absolute;
      // top: 0;
      // left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
