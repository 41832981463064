@charset "UTF-8";

/*----------------------------------------
Variables
----------------------------------------*/

/*
# Color */
$black: #000;
/* $black: #111; */
$white: #fff;
/* $white: #fff; */
$gray-black: #333;
/* $gray-black: #333; */
$primary: #25282d;
/* $primary: #343434; */
$white-gray: #e6e6e6;
/* $white-gray: #e6e6e6; */
$gray: #ccc;
/* $gray: #ccc; */
$gray-dull: #999;
/* $gray-dull: #999; */
$red: #c50000;
/* $red: #c50000; */

/*
# Font Family */
$font: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
/* $font: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif */
$font-en: 'Poppins', "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
/* $font-en: 'Poppins', "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif */

/*

# Font Weight */
$light: 300;
/* $light: 300; */
$regular:400;
/* $regular:400; */
$medium: 500;
/* $medium: 500; */
$semibold: 700;
/* $medium: 700; */
$bold: 900;
/* $bold: 900; */

/*

# Width */
$outer-width: 1920px;
/* $outer-width: 1920px; */
$content-width: 1280px;
/* $content-width: 1280px; */

/*

# Padding */
$side-padding: 115px;
/* $side-padding: 110px; */
$side-padding-mobile: 20px;
/* $side-padding-mobile: 20px; */

/*
# Easing */
$ease: cubic-bezier(.19, 1, .22, 1);
/* $ease: cubic-bezier(.19, 1, .22, 1); */

