@charset "UTF-8";

/*----------------------------------------
c-breadcrumbs
----------------------------------------*/

.c-breadcrumbs {
  &__list {
    display: flex;
    white-space: nowrap;

    .c-header.is-right & {
      justify-content: flex-end;
    }

    &-item {
      @include fz(12);
      font-family: $font-en;
      padding-right: 23px;
      position: relative;

      @include is-mobile {
        padding-right: 15px;
      }

      &::after {
        content: "/";
        position: absolute;
        top: 0;
        right: 0;
      }

      &:not(:first-child) {
        padding-left: 18px;

        @include is-mobile {
          padding-left: 8px;
        }
      }

      &:last-child {
        padding-right: 0;

        &::after {
          content: none;
        }
      }

      &:last-child {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-link {
        @extend %btn-underline;
        padding: 5px;

        @include is-laptop {
          &:hover {
            &::before {
              transform: scaleX(1);
            }
          }
        }

        &::before {
          left: 5px;
          right: 5px;
          bottom: 2px;
          transform: scale(0);
        }

        &.-current {
          pointer-events: none;
        }
      }
    }
  }
}
