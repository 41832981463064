@charset "UTF-8";


/*----------------------------------------
Variables
----------------------------------------*/
/*
# Color */
/* $black: #111; */
/* $white: #fff; */
/* $gray-black: #333; */
/* $primary: #343434; */
/* $white-gray: #e6e6e6; */
/* $gray: #ccc; */
/* $gray-dull: #999; */
/* $red: #c50000; */
/*
# Font Family */
/* $font: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif */
/* $font-en: 'Poppins', "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif */
/*

# Font Weight */
/* $light: 300; */
/* $regular:400; */
/* $medium: 500; */
/* $medium: 700; */
/* $bold: 900; */
/*

# Width */
/* $outer-width: 1920px; */
/* $content-width: 1280px; */
/*

# Padding */
/* $side-padding: 110px; */
/* $side-padding-mobile: 20px; */
/*
# Easing */
/* $ease: cubic-bezier(.19, 1, .22, 1); */
/*----------------------------------------
Extend
----------------------------------------*/
.c-breadcrumbs__list-item-link, .c-btn__link, .c-works__posts-tag-link {
  position: relative;
  transition: color .2s ease;
}

@media screen and (min-width: 1199px) {
  .c-breadcrumbs__list-item-link:hover, .c-btn__link:hover, .c-works__posts-tag-link:hover {
    color: #999;
  }
  .c-breadcrumbs__list-item-link:hover::before, .c-btn__link:hover::before, .c-works__posts-tag-link:hover::before {
    transform: scaleX(0);
    transform-origin: right;
  }
}

.c-breadcrumbs__list-item-link::before, .c-btn__link::before, .c-works__posts-tag-link::before {
  content: "";
  height: 1px;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  background: #000;
  display: block;
  transform: scaleX(1);
  transform-origin: left;
  transition: transform .2s ease;
}

/*----------------------------------------
Keyframes
----------------------------------------*/
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/*----------------------------------------
select2
----------------------------------------*/
.select2 {
  display: block;
}

.select2.select2-container .select2-selection--single {
  height: 48px;
  border-color: #000;
  border-radius: 0;
}

.select2.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 48px;
  padding-left: 20px;
  padding-right: 20px;
}

.select2.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 48px;
}

.select2 .select2-container .select2-selection--single .select2-selection__rendered,
.select2 .select2-container--default .select2-selection--single .select2-selection__rendered,
.select2 .select2.select2-container--default .select2-selection--single .select2-selection__rendered {
  font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  width: 100%;
  border: 1px solid #25282d;
  background: #fff;
  padding: 10px 40px 10px 20px;
}

@media screen and (max-width: 767px) {
  .select2 .select2-container .select2-selection--single .select2-selection__rendered,
  .select2 .select2-container--default .select2-selection--single .select2-selection__rendered,
  .select2 .select2.select2-container--default .select2-selection--single .select2-selection__rendered {
    padding: 10px 40px 10px 15px;
  }
}

.select2 .select2-container .select2-selection--single .select2-selection__rendered:disabled,
.select2 .select2-container--default .select2-selection--single .select2-selection__rendered:disabled,
.select2 .select2.select2-container--default .select2-selection--single .select2-selection__rendered:disabled {
  background: #ccc;
  opacity: .3;
}

.select2 .select2-container .select2-selection--single {
  margin: 0;
}

.select2 .select2.select2-container .select2-selection--single {
  height: auto;
}

.select2 .select2-container--default .select2-selection--single {
  background-color: transparent;
  border: none;
  border-radius: 0;
}

.select2 .select2.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: normal;
}

.select2 .select2.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 100%;
}

.select2-results__option {
  text-align: left;
}

/*----------------------------------------
common
----------------------------------------*/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 14px;
  font-size: 1.4rem;
  color: #25282d;
  line-height: 1.8;
  font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 400;
  font-feature-settings: "palt";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  word-break: normal;
  overflow-wrap: break-word;
  position: relative;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  body {
    padding-top: 132px;
  }
}

@media screen and (max-width: 767px) {
  body {
    font-size: 12px;
    font-size: 1.2rem;
    padding-top: 77px;
  }
}

@media screen and (max-width: 374px) {
  body {
    min-width: 320px;
    overflow-x: auto;
  }
}

body[data-menu="active"] {
  position: relative;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

a {
  color: #25282d;
  text-decoration: none;
}

a:not([class]) {
  text-decoration: underline;
}

select,
input,
textarea {
  line-height: normal;
  appearance: none;
  padding: 0;
  border: 0;
  border-radius: 0;
  outline: none;
}

select::-ms-expand {
  display: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

::selection {
  background-color: #25282d;
  color: #fff;
}

/* スライダーチラツキ防止 */
.swiper-wrapper {
  backface-visibility: hidden;
  transition-delay: 0s;
}

.swiper-slide {
  transition-delay: 0s;
  backface-visibility: hidden;
}

strong,
b {
  font-weight: 900;
}

/*----------------------------------------
Media
----------------------------------------*/
@media screen and (max-width: 1024px) {
  .is-pc,
  .pc {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .is-pc,
  .pc {
    display: none !important;
  }
}

.is-sp,
.sp {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .is-sp,
  .sp {
    display: block !important;
  }
}

.is-tablet,
.tablet {
  display: none !important;
}

@media screen and (max-width: 1024px) {
  .is-tablet,
  .tablet {
    display: block !important;
  }
}

/*----------------------------------------
g-footer
----------------------------------------*/
.g-footer {
  margin-top: 230px;
  padding: 50px 115px;
}

@media screen and (max-width: 767px) {
  .g-footer {
    margin-top: 85px;
    padding: 35px 20px;
  }
}

.p-about-image + .g-footer {
  margin-top: 100px;
}

@media screen and (max-width: 767px) {
  .p-about-image + .g-footer {
    margin-top: 85px;
  }
}

.g-footer__copyright {
  color: #999;
  font-family: "Poppins", "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
}

@media screen and (max-width: 767px) {
  .g-footer__copyright {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.g-hamburger {
  display: none;
  position: relative;
  z-index: 100;
  border: 0;
  cursor: pointer;
  background: transparent;
}

@media screen and (max-width: 1024px) {
  .g-hamburger {
    display: block;
  }
}

.g-hamburger__line {
  background: #25282d;
  width: 25px;
  height: 2px;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translateX(50%);
  transition: transform .2s ease;
}

@media screen and (max-width: 767px) {
  .g-hamburger__line {
    width: 20px;
  }
}

.g-hamburger__line:first-child {
  margin: -3px 0 0;
}

.g-hamburger__line:last-child {
  margin: 3px 0 0;
}

.g-hamburger__line-container {
  display: block;
  position: relative;
  height: 35px;
  width: 35px;
}

[data-menu="active"] .g-hamburger .g-hamburger__line {
  transform-origin: center center;
}

[data-menu="active"] .g-hamburger .g-hamburger__line:first-child {
  margin: 0;
  transform: translateX(50%) rotate(135deg);
}

[data-menu="active"] .g-hamburger .g-hamburger__line:last-child {
  margin: 0;
  transform: translateX(50%) rotate(400deg);
}

/*----------------------------------------
g-header
----------------------------------------*/
.g-header {
  display: flex;
  padding: 30px 60px;
  justify-content: space-between;
  width: 100%;
  background: #fff;
}

@media screen and (max-width: 1024px) {
  .g-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

@media screen and (max-width: 767px) {
  .g-header {
    padding: 10px 10px 10px 5px;
  }
}

[data-menu="active"] .g-header::before {
  opacity: 1;
  pointer-events: initial;
}

.g-header::before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  opacity: 0;
  transition: opacity .2s ease;
  z-index: 1;
}

.g-header__logo-link {
  padding: 10px;
  display: inline-block;
}

.g-header__logo-link-img {
  height: 52px;
  width: 165px;
}

@media screen and (max-width: 767px) {
  .g-header__logo-link-img {
    height: 37px;
    width: 115px;
  }
}

/*----------------------------------------
g-nav
----------------------------------------*/
@media screen and (max-width: 1024px) {
  .g-nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    width: 480px;
    background: #fff;
    pointer-events: none;
    transform: translateX(100%);
    transition: transform 0.5s cubic-bezier(0.72, 0.16, 0.27, 1.26);
    transform-origin: right;
    padding: 110px 20px 35px;
  }
}

@media screen and (max-width: 767px) {
  .g-nav {
    width: 240px;
  }
}

[data-menu="active"] .g-nav {
  pointer-events: initial;
  transform: translateX(5%);
}

.g-nav__list {
  display: flex;
  align-items: center;
  height: 100%;
  font-family: "Poppins", "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 700;
  overflow-y: auto;
}

@media screen and (max-width: 1024px) {
  .g-nav__list {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
  }
}

.g-nav__list-item {
  text-align: left;
}

.g-nav__list-item:first-child {
  display: none;
}

@media screen and (max-width: 1024px) {
  .g-nav__list-item:first-child {
    display: block;
  }
}

.g-nav__list-item-link {
  position: relative;
  padding: 20px;
  display: block;
}

@media screen and (min-width: 1199px) {
  .g-nav__list-item-link:hover::before {
    opacity: 1;
  }
}

@media screen and (max-width: 767px) {
  .g-nav__list-item-link {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.g-nav__list-item-link::before {
  content: "";
  position: absolute;
  height: 3px;
  right: 20px;
  left: 20px;
  bottom: 5px;
  background: #000;
  display: block;
  opacity: 0;
  transition: opacity .2s ease;
}

.g-nav-sns__list {
  display: none;
}

@media screen and (max-width: 1024px) {
  .g-nav-sns__list {
    display: flex;
    justify-content: flex-start;
    margin-left: 10px;
  }
}

.g-nav-sns__list-item-link {
  font-size: 17px;
  font-size: 1.7rem;
  padding: 15px;
  display: block;
  line-height: 1;
  transition: transform .2s ease;
}

/*----------------------------------------
g-pagetop
----------------------------------------*/
.g-pagetop {
  margin-bottom: 40px;
}

@media screen and (max-width: 1024px) {
  .g-pagetop {
    position: fixed;
    bottom: 0;
    right: 10px;
    margin-bottom: 15px;
  }
}

.g-pagetop__btn {
  font-size: 12px;
  font-size: 1.2rem;
  font-family: "Poppins", "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  transition: opacity .2s ease;
  color: #000;
}

@media screen and (max-width: 767px) {
  .g-pagetop__btn {
    font-size: 10px;
    font-size: 1rem;
  }
}

@media screen and (min-width: 1199px) {
  .g-pagetop__btn:hover {
    opacity: .6;
  }
}

.g-pagetop__btn::before {
  content: "";
  background-image: url("../img/common/pagetop.svg");
  background-position: center center;
  background-size: cover;
  width: 37px;
  height: 15px;
  display: block;
  margin: 0 auto 5px;
}

@media screen and (max-width: 767px) {
  .g-pagetop__btn::before {
    width: 30px;
    height: 13px;
  }
}

@media screen and (max-width: 1024px) {
  .g-pagetop__btn-pc {
    display: none;
  }
}

/*----------------------------------------
g-side
----------------------------------------*/
.g-side {
  position: fixed;
  bottom: 0;
  right: 30px;
  height: 55vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .g-side {
    position: static;
    display: block;
    height: auto;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .g-side__list {
    display: flex;
    justify-content: center;
  }
}

.g-side__list-item-link {
  font-size: 16px;
  font-size: 1.6rem;
  padding: 10px;
  display: block;
  line-height: 1;
  transition: transform .2s ease;
}

@media screen and (min-width: 1199px) {
  .g-side__list-item-link:hover {
    transform: scale(1.5);
  }
}

@media screen and (max-width: 1024px) {
  .g-side__list-item-link {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

/*----------------------------------------
c-breadcrumbs
----------------------------------------*/
.c-breadcrumbs__list {
  display: flex;
  white-space: nowrap;
}

.c-header.is-right .c-breadcrumbs__list {
  justify-content: flex-end;
}

.c-breadcrumbs__list-item {
  font-size: 12px;
  font-size: 1.2rem;
  font-family: "Poppins", "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  padding-right: 23px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .c-breadcrumbs__list-item {
    padding-right: 15px;
  }
}

.c-breadcrumbs__list-item::after {
  content: "/";
  position: absolute;
  top: 0;
  right: 0;
}

.c-breadcrumbs__list-item:not(:first-child) {
  padding-left: 18px;
}

@media screen and (max-width: 767px) {
  .c-breadcrumbs__list-item:not(:first-child) {
    padding-left: 8px;
  }
}

.c-breadcrumbs__list-item:last-child {
  padding-right: 0;
}

.c-breadcrumbs__list-item:last-child::after {
  content: none;
}

.c-breadcrumbs__list-item:last-child {
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-breadcrumbs__list-item-link {
  padding: 5px;
}

@media screen and (min-width: 1199px) {
  .c-breadcrumbs__list-item-link:hover::before {
    transform: scaleX(1);
  }
}

.c-breadcrumbs__list-item-link::before {
  left: 5px;
  right: 5px;
  bottom: 2px;
  transform: scale(0);
}

.c-breadcrumbs__list-item-link.-current {
  pointer-events: none;
}

/*----------------------------------------
c-btn
----------------------------------------*/
.c-btn__link {
  font-size: 16px;
  font-size: 1.6rem;
  font-family: "Poppins", "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 700;
  padding: 20px;
}

@media screen and (max-width: 767px) {
  .c-btn__link {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.c-btn__link::before {
  height: 3px;
  bottom: 10px;
  right: 20px;
  left: 20px;
}

@media screen and (max-width: 767px) {
  .c-btn__link::before {
    bottom: 15px;
  }
}

/*----------------------------------------
c-form
----------------------------------------*/
.c-form {
  width: 100%;
  display: block;
}

.c-form__row:last-child .c-form__ttl {
  padding-top: 45px;
  vertical-align: top;
}

@media screen and (max-width: 1024px) {
  .c-form__row:last-child .c-form__ttl {
    padding-top: 0;
  }
}

@media screen and (max-width: 1024px) {
  .c-form__table, .c-form__tbody, .c-form__row, .c-form__ttl, .c-form__cnt {
    display: block;
  }
}

.c-form__table {
  width: 100%;
}

.c-form__ttl, .c-form__cnt {
  padding: 25px 0;
  text-align: left;
}

.c-form__ttl {
  width: 240px;
  white-space: nowrap;
}

@media screen and (max-width: 1024px) {
  .c-form__ttl {
    width: 100%;
    padding-top: 0;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .c-form__ttl {
    padding-bottom: 10px;
  }
}

.c-form__cnt {
  width: calc(100% - 240px);
}

@media screen and (max-width: 1024px) {
  .c-form__cnt {
    width: 100%;
    padding-top: 0;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  .c-form__cnt {
    padding-bottom: 25px;
  }
}

.c-form__cnt .error {
  margin: 10px 0 0;
}

.c-form__cnt .horizontal-item {
  padding: 5px 0;
  display: inline-block;
}

.c-form__label {
  font-size: 16px;
  font-size: 1.6rem;
  font-family: "Poppins", "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 700;
}

@media screen and (max-width: 767px) {
  .c-form__label {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.c-form__label-val {
  margin: 0 0 0 5px;
  color: #c50000;
}

.c-form__txt.is-error:not([type="radio"]):not([type="checkbox"]) {
  background: rgba(197, 0, 0, 0.2);
}

.c-form input:not([type="radio"]):not([type="checkbox"]),
.c-form textarea,
.c-form select {
  font-size: 14px;
  font-size: 1.4rem;
  font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  width: 100%;
  appearance: none;
  padding: 15px 20px;
  border: 1px solid transparent;
  transition: border-color 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  border-color: #e6e6e6;
  background: #e6e6e6;
}

@media screen and (max-width: 767px) {
  .c-form input:not([type="radio"]):not([type="checkbox"]),
  .c-form textarea,
  .c-form select {
    padding: 10px;
  }
}

.c-form input:not([type="radio"]):not([type="checkbox"])::placeholder,
.c-form textarea::placeholder,
.c-form select::placeholder {
  color: #999;
}

@media screen and (max-width: 767px) {
  .c-form input:not([type="radio"]):not([type="checkbox"])::placeholder,
  .c-form textarea::placeholder,
  .c-form select::placeholder {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-form input:not([type="radio"]):not([type="checkbox"]):hover, .c-form input:not([type="radio"]):not([type="checkbox"]):focus,
.c-form textarea:hover,
.c-form textarea:focus,
.c-form select:hover,
.c-form select:focus {
  border-color: #25282d;
}

.c-form textarea {
  width: 100%;
  min-height: 80px;
  resize: vertical;
}

.c-form input[type="radio"], .c-form input[type="checkbox"] {
  display: none;
}

.c-form input[type="radio"] + span, .c-form input[type="checkbox"] + span {
  position: relative;
  cursor: pointer;
  padding: 0 0 0 35px;
  margin: 0 30px 0 0;
  display: inline-block;
  line-height: 24px;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .c-form input[type="radio"] + span, .c-form input[type="checkbox"] + span {
    line-height: 1.7;
  }
}

.c-form input[type="radio"] + span::before, .c-form input[type="checkbox"] + span::before {
  content: "";
  display: inline-block;
  vertical-align: sub;
  border: 1px solid #ccc;
  background: #fff;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 767px) {
  .c-form input[type="radio"] + span::before, .c-form input[type="checkbox"] + span::before {
    width: 16px;
    height: 16px;
  }
}

.c-form input[type="radio"] + span::after, .c-form input[type="checkbox"] + span::after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 4px;
  left: 4px;
  transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
}

.c-form input[type="radio"]:checked + span::after, .c-form input[type="checkbox"]:checked + span::after {
  opacity: 1;
}

.c-form input[type="radio"] + span::before {
  border-radius: 50%;
}

.c-form input[type="radio"] + span::after {
  border-radius: 50%;
  background: #000;
}

.c-form input[type="checkbox"] + span::before {
  border-width: 2px;
  border-radius: 1px;
}

.c-form input[type="checkbox"] + span::after {
  width: 5px;
  left: 8px;
  top: 4px;
  border: solid #000;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

@media screen and (max-width: 767px) {
  .c-form input[type="checkbox"] + span::after {
    left: 6px;
    top: 2px;
  }
}

.c-form-privacy {
  height: 360px;
  margin: 60px 0 0;
  border: 1px solid #999;
  padding: 10px;
}

@media screen and (max-width: 767px) {
  .c-form-privacy {
    margin-top: 35px;
  }
}

.c-form-privacy__inner {
  text-align: left;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
}

@media screen and (max-width: 767px) {
  .c-form-privacy__inner {
    padding: 20px;
  }
}

.c-form-privacy__inner::-webkit-scrollbar {
  width: 10px;
  padding: 10px;
}

.c-form-privacy__inner::-webkit-scrollbar-track {
  background-color: transparent;
}

.c-form-privacy__inner::-webkit-scrollbar-thumb {
  background-color: #e6e6e6;
}

.c-form-privacy__ttl {
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  font-weight: 900;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .c-form-privacy__ttl {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
}

.c-form-privacy__txt {
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .c-form-privacy__txt {
    margin-bottom: 20px;
  }
}

.c-form-privacy__list-item {
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .c-form-privacy__list-item {
    margin-bottom: 20px;
  }
}

.c-form-privacy__list-ttl {
  font-size: 16px;
  font-size: 1.6rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  font-weight: 900;
  margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
  .c-form-privacy__list-ttl {
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
}

.c-form-privacy__order {
  counter-reset: order 0;
}

.c-form-privacy__order-item {
  padding-left: 20px;
  position: relative;
}

.c-form-privacy__order-item::before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  content: counter(order) ".";
  counter-increment: order 1;
}

.c-form-privacy__order-item:not(:last-child) {
  margin-bottom: 15px;
}

.c-form-privacy__order-txt:not(:last-child) {
  margin-bottom: 15px;
}

.c-form__consent {
  font-size: 16px;
  font-size: 1.6rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  text-indent: .1rem;
  text-align: center;
  padding: 42.5px 0 0;
}

@media screen and (max-width: 767px) {
  .c-form__consent {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-form__consent:not(:first-child) {
  padding-top: 55px;
}

@media screen and (max-width: 767px) {
  .c-form__consent:not(:first-child) {
    padding-top: 40px;
  }
}

.c-form__consent a {
  text-decoration: underline;
}

.c-form__consent .error {
  margin: 10px 0 0;
}

.c-form__consent input[type="checkbox"] + span, .c-form__consent input[type="radio"] + span {
  font-weight: 500;
  margin: 0;
}

.c-form__consent-link {
  color: #c50000;
}

.c-form-done {
  text-align: left;
}

.c-form-done__ttl {
  font-size: 24px;
  font-size: 2.4rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  margin-bottom: 20px;
  font-weight: 900;
}

@media screen and (max-width: 767px) {
  .c-form-done__ttl {
    margin-bottom: 10px;
  }
}

.c-form-done__desc {
  font-size: 16px;
  font-size: 1.6rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .c-form-done__desc {
    font-size: 12px;
    font-size: 1.2rem;
    margin-bottom: 50px;
  }
}

.c-form-done__btn {
  margin-left: -20px;
}

.c-form-btn {
  margin-top: 55px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .c-form-btn {
    margin-top: 40px;
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.c-form-btn__body {
  color: #fff;
  padding: 25px;
  background: #000;
  border: 0;
  min-width: 260px;
  cursor: pointer;
  border: 1px solid #000;
  transition: background-color .2s ease;
}

@media screen and (max-width: 1024px) {
  .c-form-btn__body {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .c-form-btn__body {
    min-width: 0;
    margin: 0 auto;
    padding: 20px 40px;
  }
}

.c-form-btn__body.is-back {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

@media screen and (min-width: 1199px) {
  .c-form-btn__body.is-back:hover {
    color: #fff;
    background-color: #000;
  }
}

.c-form-btn__body.is-confirm {
  min-width: 400px;
}

@media screen and (max-width: 767px) {
  .c-form-btn__body.is-confirm {
    min-width: 0;
  }
}

@media screen and (min-width: 1199px) {
  .c-form-btn__body:hover {
    color: #000;
    background: #fff;
  }
}

.c-form-btn__body:not(:first-child) {
  margin-left: 20px;
}

@media screen and (max-width: 767px) {
  .c-form-btn__body:not(:first-child) {
    margin: 20px auto 0;
  }
}

.c-form-btn__body-txt {
  font-size: 16px;
  font-size: 1.6rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  line-height: 1.4;
  font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  .c-form-btn__body-txt {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.mw_wp_form_confirm .is-confirm-none {
  display: none;
}

.mw_wp_form_confirm .c-form-ttl {
  padding: 20px 0;
}

.mw_wp_form_confirm .c-form-cnt {
  background: #e6e6e6;
  padding: 20px 0 20px 40px;
}

@media screen and (max-width: 767px) {
  .mw_wp_form_confirm .c-form-cnt {
    padding: 20px;
  }
}

.mw_wp_form_confirm .c-form-label-val {
  display: none;
}

.mw_wp_form_confirm .c-form-lead {
  display: none;
}

.mw_wp_form_confirm .c-form-lead__confirm {
  display: block;
}

.mw_wp_form_input .is-input-none {
  display: none;
}

/*----------------------------------------
c-header
----------------------------------------*/
.c-header {
  padding: 80px 0;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .c-header {
    padding: 50px 0;
  }
}

.c-header.is-right {
  text-align: right;
}

.c-header__ttl {
  font-size: 80px;
  font-size: 8rem;
  letter-spacing: -2.5px;
  letter-spacing: -0.25rem;
  font-family: "Poppins", "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 700;
}

@media screen and (max-width: 767px) {
  .c-header__ttl {
    font-size: 36px;
    font-size: 3.6rem;
  }
}

.c-v-modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 0;
  height: 0;
  opacity: 0;
  background: #000;
  transition: opacity .4s ease .1s;
}

.c-v-modal-bg.is-active {
  width: 100%;
  height: 100%;
  opacity: 1;
}

.c-v-modal-btn {
  font-size: 15px;
  font-size: 1.5rem;
  display: block;
  border: 0;
  text-align: left;
  background: transparent;
  outline: none;
  text-decoration: underline;
  cursor: pointer;
}

.c-v-modal-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 5%;
  max-width: calc(1200px + 10%);
  max-height: 100%;
  width: 100%;
}

.c-v-modal-close {
  position: absolute;
  z-index: 2;
  right: 5%;
  display: inline-block;
  width: 40px;
  height: 40px;
  transform: translateX(-50%);
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .c-v-modal-close {
    width: 30px;
    height: 30px;
  }
}

.c-v-modal-close::before, .c-v-modal-close::after {
  content: "";
  position: absolute;
  top: 50%;
  display: inline-block;
  width: 40px;
  height: 3px;
  background: #fff;
  transform: rotate(45deg);
}

@media screen and (max-width: 767px) {
  .c-v-modal-close::before, .c-v-modal-close::after {
    width: 30px;
    height: 2px;
  }
}

.c-v-modal-close::after {
  transform: rotate(-45deg);
}

.c-v-modal-youtube {
  position: relative;
  width: 100%;
  max-height: 600px;
  height: calc(100vh - 50px);
  margin-top: 50px;
}

@media screen and (max-width: 767px) {
  .c-v-modal-youtube {
    margin-top: 30px;
    max-height: 450px;
  }
}

.c-v-modal-youtube iframe {
  width: 100%;
  height: 100%;
}

/*----------------------------------------
c-works
----------------------------------------*/
.c-works__ttl {
  font-size: 18px;
  font-size: 1.8rem;
  text-align: left;
  margin-bottom: 60px;
  font-weight: 700;
}

@media screen and (max-width: 767px) {
  .c-works__ttl {
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: 30px;
  }
}

.c-works-categories {
  margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .c-works-categories {
    margin-bottom: 50px;
  }
}

.c-works-categories__list {
  width: 100%;
  display: flex;
  position: relative;
}

.c-works-categories__list::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: #999;
}

.c-works-categories__list-item:first-child {
  margin-right: auto;
}

.c-works-categories__list-item:not(:first-child) {
  margin-left: 60px;
}

.c-works-categories__list-item.is-active .c-works-categories__list-item-link::after {
  opacity: 1;
}

.c-works-categories__list-item-link {
  font-size: 16px;
  font-size: 1.6rem;
  font-family: "Poppins", "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 700;
  position: relative;
  padding: 13px 0;
  display: block;
}

@media screen and (min-width: 1199px) {
  .c-works-categories__list-item-link:hover::after {
    opacity: 1;
  }
}

@media screen and (max-width: 767px) {
  .c-works-categories__list-item-link {
    font-size: 13px;
    font-size: 1.3rem;
    padding: 3px 0;
  }
}

.c-works-categories__list-item-link::after {
  content: "";
  position: absolute;
  height: 3px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: 0;
  transition: opacity .2s ease;
}

.c-works__posts-list {
  display: flex;
  flex-wrap: wrap;
}

.c-works__posts-list-item {
  width: calc(50% - 160px);
  margin: 80px;
}

@media screen and (max-width: 1024px) {
  .c-works__posts-list-item {
    width: 100%;
    margin: 35px 0;
  }
}

.c-works__posts-list-item-link {
  display: block;
  position: relative;
  transition: opacity .2s ease;
}

@media screen and (min-width: 1199px) {
  .c-works__posts-list-item-link:hover {
    opacity: .8;
  }
}

.c-works__posts-list-item-figure {
  position: relative;
  padding-bottom: 75%;
  width: 100%;
}

.is-movie .c-works__posts-list-item-figure::after {
  content: "";
  background-image: url("../img/common/movie_ico.svg");
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 60px;
  height: 60px;
  opacity: 1;
  transition: opacity .2s ease;
}

.is-taxonomy-photo .is-movie .c-works__posts-list-item-figure::after {
  content: none;
}

.c-works__posts-list-item-figure-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.c-works__posts-list-item-img {
  display: none;
}

.c-works__posts-list-item-ttl {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 25px;
}

@media screen and (max-width: 767px) {
  .c-works__posts-list-item-ttl {
    font-size: 13px;
    font-size: 1.3rem;
    margin-top: 15px;
  }
}

.c-works__posts-tag {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 5px -5px -5px;
}

@media screen and (max-width: 767px) {
  .c-works__posts-tag {
    margin: 5px -5px 0;
  }
}

.c-works__posts-tag.is-single {
  justify-content: flex-start;
}

.c-works__posts-tag-link {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0 5px;
}

@media screen and (min-width: 1199px) {
  .c-works__posts-tag-link:hover::before {
    transform: scaleX(1);
  }
}

@media screen and (max-width: 767px) {
  .c-works__posts-tag-link {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.c-works__posts-tag-link::before {
  left: 5px;
  right: 5px;
  bottom: 5px;
  transform: scale(0);
}

.c-works-more {
  margin-top: 160px;
}

.l-content__main {
  padding: 0 115px;
}

@media screen and (max-width: 767px) {
  .l-content__main {
    padding: 0 20px;
  }
}

.l-content__container {
  max-width: calc(1920px + 230px);
  margin: 0 auto;
}

.l-content__container-s {
  max-width: calc(1280px + 230px);
  margin: 0 auto;
}

.l-main {
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  padding: 0 115px;
}

@media screen and (max-width: 767px) {
  .l-main {
    padding: 0 20px;
  }
}

/*----------------------------------------
p-404
----------------------------------------*/
.p-404 {
  text-align: left;
}

.p-404__ttl {
  font-size: 24px;
  font-size: 2.4rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  margin-bottom: 20px;
  font-weight: 900;
}

@media screen and (max-width: 767px) {
  .p-404__ttl {
    margin-bottom: 10px;
  }
}

.p-404__desc {
  font-size: 16px;
  font-size: 1.6rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .p-404__desc {
    font-size: 12px;
    font-size: 1.2rem;
    margin-bottom: 50px;
  }
}

.p-404__btn {
  margin-left: -20px;
}

/*----------------------------------------
p-about
----------------------------------------*/
.p-about__ttl {
  margin-bottom: 70px;
}

@media screen and (max-width: 767px) {
  .p-about__ttl {
    margin-bottom: 35px;
  }
}

.p-about__ttl-img {
  max-width: 330px;
}

@media screen and (max-width: 767px) {
  .p-about__ttl-img {
    max-width: 195px;
  }
}

.p-about__desc {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .p-about__desc {
    text-align: left;
  }
}

.p-about__desc:not(:last-child) {
  margin-bottom: 70px;
}

@media screen and (max-width: 767px) {
  .p-about__desc:not(:last-child) {
    margin-bottom: 30px;
  }
}

.p-about-image {
  margin-top: 230px;
}

@media screen and (max-width: 767px) {
  .p-about-image {
    margin-top: 75px;
  }
}

.p-about-image__figure {
  position: relative;
  width: 100%;
  padding-bottom: 25%;
}

.p-about-image__figure-img {
  display: none;
}

.p-about-image__figure-bg {
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/*----------------------------------------
front
----------------------------------------*/
.p-front-mv {
  padding: 0 100px;
  text-align: left;
}

@media screen and (max-width: 1024px) {
  .p-front-mv {
    margin: 80px -115px 0 -115px;
    padding: 0;
  }
}

@media screen and (max-width: 767px) {
  .p-front-mv {
    margin: 80px -20px 0 -20px;
  }
}

.p-front-mv__figure-img {
  width: 60vw;
}

@media screen and (max-width: 1024px) {
  .p-front-mv__figure-img {
    width: 100%;
  }
}

.p-front-imageblock {
  padding-top: 280px;
}

@media screen and (max-width: 767px) {
  .p-front-imageblock {
    padding-top: 120px;
  }
}

.p-front-imageblock__section:not(:last-child) {
  margin-bottom: 240px;
}

@media screen and (max-width: 767px) {
  .p-front-imageblock__section:not(:last-child) {
    margin-bottom: 120px;
  }
}

.p-front-imageblock__list-item {
  display: flex;
}

.p-front-imageblock__list-item-link {
  display: block;
  position: relative;
  z-index: 0;
  opacity: 0;
  transition: opacity 1s ease, transform 1s ease;
  transform: translateY(50px);
}

.p-front-imageblock__list-item-link.is-active {
  opacity: 1;
  transform: translateY(0);
}

.p-front-imageblock__list-item-link:hover::before {
  opacity: 1;
}

.is-movie .p-front-imageblock__list-item-link:hover::after {
  opacity: 0;
}

.p-front-imageblock__list-item-link.is-no-link {
  pointer-events: none;
}

.p-front-imageblock__list-item-link::before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  pointer-events: none;
  opacity: 0;
  transition: opacity .2s ease;
  z-index: 1;
}

.is-movie .p-front-imageblock__list-item-link::after {
  content: "";
  background-image: url("../img/common/movie_ico.svg");
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 60px;
  height: 60px;
  opacity: 1;
  transition: opacity .2s ease;
  z-index: 1;
}

.p-front-imageblock__list-item-figure::before {
  content: "";
  background-image: url("../img/common/bracket_upper.svg");
  background-size: cover;
  width: 110px;
  height: 110px;
  position: absolute;
  top: -10px;
  left: -10px;
  opacity: 0;
  transition: opacity .2s ease;
}

@media screen and (max-width: 767px) {
  .p-front-imageblock__list-item-figure::before {
    display: none;
  }
}

.p-front-imageblock__list-item-link:hover .p-front-imageblock__list-item-figure::before {
  opacity: 1;
}

.p-front-imageblock__list-item-figure::after {
  content: "";
  background-image: url("../img/common/bracket_lower.svg");
  background-size: cover;
  width: 110px;
  height: 110px;
  position: absolute;
  bottom: -10px;
  right: -10px;
  opacity: 0;
  transition: opacity .2s ease;
}

@media screen and (max-width: 767px) {
  .p-front-imageblock__list-item-figure::after {
    display: none;
  }
}

.p-front-imageblock__list-item-link:hover .p-front-imageblock__list-item-figure::after {
  opacity: 1;
}

.a_block .p-front-imageblock__list-item:nth-child(1) {
  justify-content: flex-end;
  margin-right: 4.375vw;
}

@media screen and (max-width: 1024px) {
  .a_block .p-front-imageblock__list-item:nth-child(1) {
    margin-right: 0;
  }
}

.b_block .p-front-imageblock__list-item:nth-child(1) {
  justify-content: flex-start;
}

@media screen and (max-width: 1024px) {
  .b_block .p-front-imageblock__list-item:nth-child(1) {
    margin-left: -115px;
  }
}

@media screen and (max-width: 767px) {
  .b_block .p-front-imageblock__list-item:nth-child(1) {
    margin-left: -20px;
  }
}

.b_block .p-front-imageblock__list-item:nth-child(1):not(:last-child) {
  margin-bottom: 125px;
}

@media screen and (max-width: 767px) {
  .b_block .p-front-imageblock__list-item:nth-child(1):not(:last-child) {
    margin-bottom: 80px;
  }
}

.c_block .p-front-imageblock__list-item:nth-child(1) {
  justify-content: flex-end;
  margin-right: 15vw;
}

@media screen and (max-width: 1024px) {
  .c_block .p-front-imageblock__list-item:nth-child(1) {
    margin-right: 0;
  }
}

.c_block .p-front-imageblock__list-item:nth-child(1):not(:last-child) {
  margin-bottom: 240px;
}

@media screen and (max-width: 1024px) {
  .c_block .p-front-imageblock__list-item:nth-child(1):not(:last-child) {
    margin-bottom: 120px;
  }
}

.a_block .p-front-imageblock__list-item:nth-child(1) .p-front-imageblock__list-item-link {
  width: 30vw;
}

@media screen and (max-width: 1024px) {
  .a_block .p-front-imageblock__list-item:nth-child(1) .p-front-imageblock__list-item-link {
    width: 64vw;
  }
}

.b_block .p-front-imageblock__list-item:nth-child(1) .p-front-imageblock__list-item-link {
  width: 30vw;
}

@media screen and (max-width: 1024px) {
  .b_block .p-front-imageblock__list-item:nth-child(1) .p-front-imageblock__list-item-link {
    width: 64vw;
  }
}

.c_block .p-front-imageblock__list-item:nth-child(1) .p-front-imageblock__list-item-link {
  width: 80vw;
}

.p-front-imageblock__list-item:nth-child(2) {
  display: flex;
}

.a_block .p-front-imageblock__list-item:nth-child(2) {
  justify-content: flex-start;
  margin-top: -180px;
  margin-left: 15.625vw;
}

@media screen and (max-width: 1024px) {
  .a_block .p-front-imageblock__list-item:nth-child(2) {
    margin-top: 80px;
    margin-left: 6.25vw;
  }
}

.b_block .p-front-imageblock__list-item:nth-child(2) {
  justify-content: flex-end;
}

@media screen and (max-width: 1024px) {
  .b_block .p-front-imageblock__list-item:nth-child(2) {
    margin: 0 -115px;
  }
}

@media screen and (max-width: 767px) {
  .b_block .p-front-imageblock__list-item:nth-child(2) {
    margin: 0 -20px;
  }
}

.b_block .p-front-imageblock__list-item:nth-child(2):not(:last-child) {
  margin-bottom: 125px;
}

@media screen and (max-width: 767px) {
  .b_block .p-front-imageblock__list-item:nth-child(2):not(:last-child) {
    margin-bottom: 120px;
  }
}

.c_block .p-front-imageblock__list-item:nth-child(2) {
  justify-content: flex-start;
  margin-left: 14.375vw;
}

@media screen and (max-width: 1024px) {
  .c_block .p-front-imageblock__list-item:nth-child(2) {
    margin-left: 3.125vw;
  }
}

.c_block .p-front-imageblock__list-item:nth-child(2):not(:last-child) {
  margin-bottom: 80px;
}

.a_block .p-front-imageblock__list-item:nth-child(2) .p-front-imageblock__list-item-link {
  width: 20vw;
}

@media screen and (max-width: 1024px) {
  .a_block .p-front-imageblock__list-item:nth-child(2) .p-front-imageblock__list-item-link {
    width: 40vw;
  }
}

.b_block .p-front-imageblock__list-item:nth-child(2) .p-front-imageblock__list-item-link {
  width: 60vw;
}

@media screen and (max-width: 1024px) {
  .b_block .p-front-imageblock__list-item:nth-child(2) .p-front-imageblock__list-item-link {
    width: 100%;
  }
}

.c_block .p-front-imageblock__list-item:nth-child(2) .p-front-imageblock__list-item-link {
  width: 30vw;
}

@media screen and (max-width: 1024px) {
  .c_block .p-front-imageblock__list-item:nth-child(2) .p-front-imageblock__list-item-link {
    width: 64vw;
  }
}

.p-front-imageblock__list-item:nth-child(3) {
  display: flex;
}

.b_block .p-front-imageblock__list-item:nth-child(3) {
  justify-content: flex-start;
  margin-left: 5vw;
}

@media screen and (max-width: 1024px) {
  .b_block .p-front-imageblock__list-item:nth-child(3) {
    margin-left: 0;
  }
}

.c_block .p-front-imageblock__list-item:nth-child(3) {
  justify-content: flex-end;
}

@media screen and (max-width: 1024px) {
  .c_block .p-front-imageblock__list-item:nth-child(3) {
    margin-right: -115px;
  }
  .c_block .p-front-imageblock__list-item:nth-child(3):not(:last-child) {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) {
  .c_block .p-front-imageblock__list-item:nth-child(3) {
    margin-right: -20px;
  }
}

.b_block .p-front-imageblock__list-item:nth-child(3) .p-front-imageblock__list-item-link {
  width: 30vw;
}

@media screen and (max-width: 1024px) {
  .b_block .p-front-imageblock__list-item:nth-child(3) .p-front-imageblock__list-item-link {
    width: 64vw;
  }
}

.c_block .p-front-imageblock__list-item:nth-child(3) .p-front-imageblock__list-item-link {
  width: 40vw;
}

@media screen and (max-width: 1024px) {
  .c_block .p-front-imageblock__list-item:nth-child(3) .p-front-imageblock__list-item-link {
    width: 50vw;
  }
}

.p-front-imageblock__list-item:nth-child(4) {
  justify-content: flex-start;
  margin-left: 5vw;
}

@media screen and (max-width: 1600px) {
  .p-front-imageblock__list-item:nth-child(4) {
    margin-left: 0;
  }
}

.c_block .p-front-imageblock__list-item:nth-child(4) .p-front-imageblock__list-item-link {
  width: 40vw;
}

@media screen and (max-width: 1280px) {
  .c_block .p-front-imageblock__list-item:nth-child(4) .p-front-imageblock__list-item-link {
    width: 35vw;
  }
}

@media screen and (max-width: 1024px) {
  .c_block .p-front-imageblock__list-item:nth-child(4) .p-front-imageblock__list-item-link {
    width: 80vw;
  }
}

.p-front-imageblock__list-item-desc {
  font-size: 16px;
  font-size: 1.6rem;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity .2s ease;
  padding: 20px;
  font-weight: 500;
  z-index: 1;
}

.p-front-imageblock__list-item-desc::after {
  content: "";
  background-image: url("../img/common/arrow.svg");
  background-size: cover;
  width: 47px;
  height: 8px;
  display: block;
  margin-top: 30px;
}

.p-front-imageblock__list-item-link:hover .p-front-imageblock__list-item-desc {
  opacity: 1;
}

.p-front-imageblock__list-item-img {
  width: 100%;
}

.p-front-morebtn {
  margin-top: 170px;
}

@media screen and (max-width: 767px) {
  .p-front-morebtn {
    margin-top: 70px;
  }
}

/*----------------------------------------
p-member
----------------------------------------*/
.p-member__list {
  margin: -20px;
}

@media screen and (max-width: 767px) {
  .p-member__list {
    margin: -20px;
  }
}

.p-member__list-item {
  text-align: right;
}

.p-member__list-item-link {
  padding: 20px;
  display: inline-block;
}

@media screen and (min-width: 1199px) {
  .p-member__list-item-link:hover .p-member__list-item-link-name-inner {
    font-style: italic;
    color: #fff;
    background-position: 0 60%;
    background-size: 100% 70%;
  }
}

@media screen and (max-width: 767px) {
  .p-member__list-item-link {
    padding: 10px;
  }
}

.p-member__list-item-link-position {
  font-size: 16px;
  font-size: 1.6rem;
  letter-spacing: -0.5px;
  letter-spacing: -0.05rem;
  font-family: "Poppins", "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  display: block;
  line-height: 1;
}

@media screen and (max-width: 767px) {
  .p-member__list-item-link-position {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.p-member__list-item-link-name {
  font-size: 90px;
  font-size: 9rem;
  letter-spacing: -2.5px;
  letter-spacing: -0.25rem;
  display: inline-block;
  position: relative;
  font-weight: 700;
  font-family: "Poppins", "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  z-index: 0;
  line-height: 1;
}

@media screen and (max-width: 767px) {
  .p-member__list-item-link-name {
    font-size: 36px;
    font-size: 3.6rem;
  }
}

.p-member__list-item-link-name-inner {
  position: relative;
  z-index: 1;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201%201%22%20preserveAspectRatio%3D%22none%22%3E%20%20%20%3Crect%20width%3D%221%22%20height%3D%221%22%2F%3E%20%3C%2Fsvg%3E");
  transition: background-size .5s ease;
  background-repeat: no-repeat;
  background-position: 100% 60%;
  background-size: 0 70%;
  line-height: 1.2;
}

/*----------------------------------------
p-workspost
----------------------------------------*/
.p-workspost__article {
  position: relative;
  border-top: 5px solid #000;
  padding: 80px 0 200px;
}

@media screen and (max-width: 767px) {
  .p-workspost__article {
    padding: 35px 0 65px;
  }
}

.p-workspost-header {
  text-align: left;
}

.p-workspost-header:not(:last-child) {
  margin-bottom: 160px;
}

@media screen and (max-width: 767px) {
  .p-workspost-header:not(:last-child) {
    margin-bottom: 90px;
  }
}

.p-workspost-header__ttl {
  font-size: 24px;
  font-size: 2.4rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  font-weight: 900;
}

@media screen and (max-width: 767px) {
  .p-workspost-header__ttl {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.p-workspost-header__block {
  margin-top: 25px;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .p-workspost-header__block {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .p-workspost-header__block {
    margin-top: 15px;
  }
}

.p-workspost-header__desc {
  width: 62.5%;
}

@media screen and (max-width: 1024px) {
  .p-workspost-header__desc {
    width: 100%;
  }
}

.p-workspost-header__desc:not(:last-child) {
  padding-right: 70px;
  border-right: 1px solid #ccc;
}

@media screen and (max-width: 1024px) {
  .p-workspost-header__desc:not(:last-child) {
    padding-right: 0;
    padding-bottom: 70px;
    border-right: 0;
    border-bottom: 1px solid #ccc;
  }
}

@media screen and (max-width: 767px) {
  .p-workspost-header__desc:not(:last-child) {
    padding-bottom: 35px;
  }
}

.p-workspost-header-staff {
  width: 37.5%;
}

@media screen and (max-width: 1024px) {
  .p-workspost-header-staff {
    width: 100%;
  }
}

.p-workspost-header__desc + .p-workspost-header-staff {
  margin-left: 70px;
}

@media screen and (max-width: 1024px) {
  .p-workspost-header__desc + .p-workspost-header-staff {
    margin-left: 0;
    margin-top: 70px;
  }
}

@media screen and (max-width: 767px) {
  .p-workspost-header__desc + .p-workspost-header-staff {
    margin-top: 35px;
  }
}

.p-workspost-header-staff__list-item {
  display: flex;
  font-family: "Poppins", "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
}

.p-workspost-header-staff__list-item-ttl {
  width: 220px;
  padding-right: 40px;
}

@media screen and (max-width: 767px) {
  .p-workspost-header-staff__list-item-ttl {
    width: 160px;
    padding-right: 30px;
  }
}

.p-workspost-header-staff__list-item-desc {
  width: calc(100% - 220px);
}

@media screen and (max-width: 767px) {
  .p-workspost-header-staff__list-item-desc {
    width: calc(100% - 160px);
  }
}

.p-workspost-header-staff__list-item-link {
  position: relative;
}

.p-workspost-header-staff__list-item-link::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 1px;
  background-color: #000;
}

.p-workspost-header-staff__list-item-link.is-no-link {
  pointer-events: none;
}

.p-workspost-header-staff__list-item-link.is-no-link::after {
  content: none;
}

.p-workspost-contents__block {
  width: 60vw;
  border: 0;
  background-color: transparent;
  position: relative;
  transition: opacity .2s ease;
  margin: 0 auto;
}

@media screen and (min-width: 1199px) {
  .p-workspost-contents__block:hover.is-movie {
    opacity: .8;
  }
}

@media screen and (max-width: 1024px) {
  .p-workspost-contents__block {
    width: 80vw;
  }
}

.p-workspost-contents__block:not(:last-child) {
  margin-bottom: 220px;
}

@media screen and (max-width: 767px) {
  .p-workspost-contents__block:not(:last-child) {
    margin-bottom: 90px;
  }
}

.p-workspost-contents__block.is-movie {
  cursor: pointer;
}

.p-workspost-contents__block.is-movie::before {
  content: "";
  background-image: url("../img/common/movie_ico.svg");
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 60px;
  height: 60px;
  opacity: 1;
  transition: opacity .2s ease;
  z-index: 1;
}

.p-workspost-contents__figure {
  position: relative;
  width: 100%;
  padding-bottom: 75%;
}

.p-workspost-contents__figure-img {
  display: none;
}

.p-workspost-contents__figure-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.p-workspost__btn {
  text-align: left;
}
