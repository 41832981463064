@charset "UTF-8";

/*----------------------------------------
g-footer
----------------------------------------*/

.g-footer {
  margin-top: 230px;
  padding: 50px $side-padding;

  @include is-mobile {
    margin-top: 85px;
    padding: 35px $side-padding-mobile;
  }

  .p-about-image + & {
    margin-top: 100px;

    @include is-mobile {
      margin-top: 85px;
    }
  }

  &__copyright {
    color: $gray-dull;
    font-family: $font-en;

    @include is-mobile {
      @include fz(12);
    }
  }
}
