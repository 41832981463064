
// Mixin

@mixin clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

@mixin fz($size: 24, $base: 16) {
  font-size: $size + px;
  font-size: ($size / ($base * .625)) + rem;
}

@mixin ls($size: 0, $base: 16) {
  letter-spacing: $size + px;
  letter-spacing: ($size / ($base * .625)) + rem;
}

@mixin is-fw {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}