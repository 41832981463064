@charset "UTF-8";

/*----------------------------------------
common
----------------------------------------*/

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  @include fz(14);
  color: $primary;
  line-height: 1.8;
  font-family: $font;
  font-weight: $regular;
  font-feature-settings: "palt";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  word-break: normal;
  overflow-wrap: break-word;
  position: relative;
  text-align: center;

  @include is-tablet {
    padding-top: 132px;
  }

  @include is-mobile {
    @include fz(12);
    padding-top: 77px;
  }

  @include is-mobile-small {
    min-width: 320px;
    overflow-x: auto;
  }

  &[data-menu="active"] {
    position: relative;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

a {
  color: $primary;
  text-decoration: none;

  &:not([class]) {
    text-decoration: underline;
  }
}

select,
input,
textarea {
  line-height: normal;
  appearance: none;
  padding: 0;
  border: 0;
  border-radius: 0;
  outline: none;
}

select::-ms-expand {
  display: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

::selection {
  background-color: $primary;
  color: $white;
}

/* スライダーチラツキ防止 */
.swiper-wrapper {
  backface-visibility: hidden;
  transition-delay: 0s;
}

.swiper-slide {
  transition-delay: 0s;
  backface-visibility: hidden;

}

strong,
b {
  font-weight: $bold;
}

/*----------------------------------------
Media
----------------------------------------*/

.is-pc,
.pc {
  @include is-tablet {
    // scss-lint:disable ImportantRule
    display: none !important;
  }

  @include is-mobile {
    // scss-lint:disable ImportantRule
    display: none !important;
  }
}

.is-sp,
.sp {
  // scss-lint:disable ImportantRule
  display: none !important;

  @include is-mobile {
    // scss-lint:disable ImportantRule
    display: block !important;
  }
}

.is-tablet,
.tablet {
  // scss-lint:disable ImportantRule
  display: none !important;

  @include is-tablet {
    // scss-lint:disable ImportantRule
    display: block !important;
  }
}


