@charset "UTF-8";

/*----------------------------------------
p-member
----------------------------------------*/

.p-member {
  &__list {
    margin: -20px;

    @include is-mobile {
      margin: -20px;
    }

    &-item {
      text-align: right;

      &-link {
        padding: 20px;
        display: inline-block;

        @include is-laptop {
          &:hover &-name {

            &-inner {
              font-style: italic;
              color: $white;
              background-position: 0 60%;
              background-size: 100% 70%;
            }
          }
        }

        @include is-mobile {
          padding: 10px;
        }

        &-position {
          @include fz(16);
          @include ls(-.5);
          font-family: $font-en;
          display: block;
          line-height: 1;

          @include is-mobile {
            @include fz(14);
          }
        }

        &-name {
          @include fz(90);
          @include ls(-2.5);
          display: inline-block;
          position: relative;
          font-weight: $semibold;
          font-family: $font-en;
          z-index: 0;
          line-height: 1;

          @include is-mobile {
            @include fz(36);
          }

          &-inner {
            position: relative;
            z-index: 1;
            background: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201%201%22%20preserveAspectRatio%3D%22none%22%3E%20%20%20%3Crect%20width%3D%221%22%20height%3D%221%22%2F%3E%20%3C%2Fsvg%3E");
            transition: background-size .5s ease;
            background-repeat: no-repeat;
            background-position: 100% 60%;
            background-size: 0 70%;
            line-height: 1.2;
          }
        }
      }


    }
  }
}
