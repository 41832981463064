@charset "UTF-8";

/*----------------------------------------
c-form
----------------------------------------*/

.c-form {
  $this: &;
  width: 100%;
  display: block;

  &__row {
    &:last-child #{$this}__ttl {
      padding-top: 45px;
      vertical-align: top;

      @include is-tablet {
        padding-top: 0;
      }
    }
  }

  &__table,
  &__tbody,
  &__row,
  &__ttl,
  &__cnt {
    @include is-tablet {
      display: block;
    }
  }

  &__table {
    width: 100%;
  }

  &__ttl,
  &__cnt {
    padding: 25px 0;
    text-align: left;
  }

  &__ttl {
    width: 240px;
    white-space: nowrap;

    @include is-tablet {
      width: 100%;
      padding-top: 0;
      padding-bottom: 20px;
    }

    @include is-mobile {
      padding-bottom: 10px;
    }
  }

  &__cnt {
    width: calc(100% - 240px);

    @include is-tablet {
      width: 100%;
      padding-top: 0;
      padding-bottom: 30px;
    }

    @include is-mobile {
      padding-bottom: 25px;
    }

    .error {
      margin: 10px 0 0;
    }

    .horizontal-item {
      padding: 5px 0;
      display: inline-block;
    }
  }

  &__label {
    @include fz(16);
    font-family: $font-en;
    font-weight: $semibold;

    @include is-mobile {
      @include fz(13);
    }

    &-val {
      margin: 0 0 0 5px;
      color: $red;

    }
  }

  &__txt {
    &.is-error {
      &:not([type="radio"]):not([type="checkbox"]) {
        background: rgba($red, .2);
      }
    }
  }

  input:not([type="radio"]):not([type="checkbox"]),
  textarea,
  select {
    @include fz(14);
    font-family: $font;
    width: 100%;
    appearance: none;
    padding: 15px 20px;
    border: 1px solid transparent;
    transition: border-color .5s $ease;
    border-color: $white-gray;
    background: $white-gray;

    @include is-mobile {
      padding: 10px;
    }

    &::placeholder {
      color: $gray-dull;

      @include is-mobile {
        @include fz(12);
      }
    }

    &:hover,
    &:focus {
      border-color: $primary;
    }
  }

  textarea {
    width: 100%;
    min-height: 80px;
    resize: vertical;
  }

  input {
    &[type="radio"],
    &[type="checkbox"] {
      display: none;

      + span {
        position: relative;
        cursor: pointer;
        padding: 0 0 0 35px;
        margin: 0 30px 0 0;
        display: inline-block;
        line-height: 24px;
        text-align: left;

        @include is-mobile {
          line-height: 1.7;
        }

        &::before {
          content: "";
          display: inline-block;
          vertical-align: sub;
          border: 1px solid $gray;
          background: $white;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 0;
          left: 0;

          @include is-mobile {
            width: 16px;
            height: 16px;
          }
        }

        &::after {
          content: "";
          display: inline-block;
          width: 10px;
          height: 10px;
          position: absolute;
          top: 4px;
          left: 4px;
          transition: opacity .3s $ease;
          opacity: 0;
        }
      }

      &:checked + span {
        &::after {
          opacity: 1;
        }
      }
    }

    &[type="radio"] {
      + span {
        &::before {
          border-radius: 50%;
        }

        &::after {
          border-radius: 50%;
          background: $black;
        }
      }
    }

    &[type="checkbox"] {
      + span {
        &::before {
          border-width: 2px;
          border-radius: 1px;
        }

        &::after {
          width: 5px;
          left: 8px;
          top: 4px;
          border: solid $black;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);

          @include is-mobile {
            left: 6px;
            top: 2px;
          }
        }
      }
    }
  }

  &-privacy {
    height: 360px;
    margin: 60px 0 0;
    border: 1px solid $gray-dull;
    padding: 10px;

    @include is-mobile {
      margin-top: 35px;
    }

    &__inner {
      text-align: left;
      height: 100%;
      padding: 30px;
      overflow-y: auto;

      @include is-mobile {
        padding: 20px;
      }

      &::-webkit-scrollbar {
        width: 10px;
        padding: 10px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $white-gray;
      }
    }

    &__ttl {
      @include fz(18);
      @include ls(1);
      font-weight: $bold;
      margin-bottom: 20px;

      @include is-mobile {
        @include fz(16);
        margin-bottom: 10px;
      }
    }

    &__txt {
      margin-bottom: 30px;

      @include is-mobile {
        margin-bottom: 20px;
      }
    }

    &__list {
      &-item {
        margin-bottom: 30px;

        @include is-mobile {
          margin-bottom: 20px;
        }
      }

      &-ttl {
        @include fz(16);
        @include ls(1);
        font-weight: $bold;
        margin-bottom: 15px;

        @include is-mobile {
          @include fz(14);
          margin-bottom: 10px;
        }
      }
    }

    &__order {
      counter-reset: order 0;

      &-item {
        padding-left: 20px;
        position: relative;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          display: inline-block;
          content: counter(order) ".";
          counter-increment: order 1;
        }

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }

      &-txt {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }

  }

  &__consent {
    @include fz(16);
    @include ls(1);
    text-indent: .1rem;
    text-align: center;
    padding: 42.5px 0 0;

    @include is-mobile {
      @include fz(12);
    }

    &:not(:first-child) {
      padding-top: 55px;

      @include is-mobile {
        padding-top: 40px;
      }
    }

    a {
      text-decoration: underline;
    }

    .error {
      margin: 10px 0 0;
    }

    input {
      &[type="checkbox"],
      &[type="radio"] {
        + span {
          font-weight: $medium;
          margin: 0;
        }
      }
    }

    &-link {
      color: $red;
    }
  }

  &-done {
    text-align: left;

    &__ttl {
      @include fz(24);
      @include ls(1);
      margin-bottom: 20px;
      font-weight: $bold;

      @include is-mobile {
        margin-bottom: 10px;
      }
    }

    &__desc {
      @include fz(16);
      @include ls(1);
      margin-bottom: 100px;

      @include is-mobile {
        @include fz(12);
        margin-bottom: 50px;
      }
    }

    &__btn {
      margin-left: -20px;
    }
  }

}

.c-form-btn {
  margin-top: 55px;
  display: flex;
  justify-content: center;

  @include is-mobile {
    margin-top: 40px;
    flex-wrap: wrap;
    flex-direction: column;
  }

  &__body {
    color: $white;
    padding: 25px;
    background: $black;
    border: 0;
    min-width: 260px;
    cursor: pointer;
    border: 1px solid $black;
    transition: background-color .2s ease;

    @include is-tablet {
      width: 100%;
    }

    @include is-mobile {
      min-width: 0;
      margin: 0 auto;
      padding: 20px 40px;
    }

    &.is-back {
      color: $black;
      background-color: $white;
      border: 1px solid $black;

      &:hover {
        @include is-laptop {
          color: $white;
          background-color: $black;
        }
      }
    }

    &.is-confirm {
      min-width: 400px;

      @include is-mobile {
        min-width: 0;
      }
    }

    &:hover {
      @include is-laptop {
        color: $black;
        background: $white;
      }
    }

    &:not(:first-child) {
      margin-left: 20px;

      @include is-mobile {
        margin: 20px auto 0;
      }
    }

    &-txt {
      @include fz(16);
      @include ls(1);
      line-height: 1.4;
      font-family: $font;
      font-weight: $medium;

      @include is-mobile {
        @include fz(13);
      }
    }
  }


}

// scss-lint:disable SelectorFormat
.mw_wp_form_confirm {
  .is-confirm-none {
    display: none;
  }

  .c-form-ttl {
    padding: 20px 0;
  }

  .c-form-cnt {
    background: $white-gray;
    padding: 20px 0 20px 40px;

    @include is-mobile {
      padding: 20px;
    }
  }

  .c-form-label {
    &-val {
      display: none;
    }
  }

  .c-form-lead {
    display: none;

    &__confirm {
      display: block;
    }
  }
}

// scss-lint:disable SelectorFormat
.mw_wp_form_input .is-input-none {
  display: none;
}
