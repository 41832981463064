@charset "UTF-8";

/*----------------------------------------
p-about
----------------------------------------*/

.p-about {
  &__ttl {
    margin-bottom: 70px;

    @include is-mobile {
      margin-bottom: 35px;
    }

    &-img {
      max-width: 330px;

      @include is-mobile {
        max-width: 195px;
      }
    }
  }

  &__desc {
    text-align: center;

    @include is-mobile {
      text-align: left;
    }

    &:not(:last-child) {
      margin-bottom: 70px;

      @include is-mobile {
        margin-bottom: 30px;
      }
    }
  }

  &-image {
    margin-top: 230px;

    @include is-mobile {
      margin-top: 75px;
    }

    &__figure {
      position: relative;
      width: 100%;
      padding-bottom: 25%;

      &-img {
        display: none;
      }

      &-bg {
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

  }
}
