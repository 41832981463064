@charset "UTF-8";

/*----------------------------------------
g-header
----------------------------------------*/

.g-header {
  display: flex;
  padding: 30px 60px;
  justify-content: space-between;
  width: 100%;
  background: $white;

  @include is-tablet {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }

  @include is-mobile {
    padding: 10px 10px 10px 5px;
  }

  [data-menu="active"] & {
    &::before {
      opacity: 1;
      pointer-events: initial;
    }
  }

  &::before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($black, .5);
    pointer-events: none;
    opacity: 0;
    transition: opacity .2s ease;
    z-index: 1;
  }

  &__logo {
    &-link {
      padding: 10px;
      display: inline-block;

      &-img {
        height: 52px;
        width: 165px;

        @include is-mobile {
          height: 37px;
          width: 115px;
        }
      }
    }
  }
}
