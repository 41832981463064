@charset "UTF-8";

/*----------------------------------------
c-header
----------------------------------------*/

.c-header {
  padding: 80px 0;
  text-align: left;

  @include is-mobile {
    padding: 50px 0;
  }

  &.is-right {
    text-align: right;
  }

  &__ttl {
    @include fz(80);
    @include ls(-2.5);
    font-family: $font-en;
    font-weight: $semibold;

    @include is-mobile {
      @include fz(36);
    }
  }
}
