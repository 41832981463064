@charset "UTF-8";

/*----------------------------------------
p-workspost
----------------------------------------*/

.p-workspost {
  &__article {
    position: relative;
    border-top: 5px solid $black;
    padding: 80px 0 200px;

    @include is-mobile {
      padding: 35px 0 65px;
    }
  }

  &-header {
    $this: &;
    text-align: left;

    &:not(:last-child) {
      margin-bottom: 160px;

      @include is-mobile {
        margin-bottom: 90px;
      }
    }

    &__ttl {
      @include fz(24);
      @include ls(1);
      font-weight: $bold;

      @include is-mobile {
        @include fz(16);
      }
    }

    &__block {
      margin-top: 25px;
      display: flex;

      @include is-tablet {
        display: block;
      }

      @include is-mobile {
        margin-top: 15px;
      }
    }

    &__desc {
      width: 62.5%;

      @include is-tablet {
        width: 100%;
      }

      &:not(:last-child) {
        padding-right: 70px;
        border-right: 1px solid $gray;

        @include is-tablet {
          padding-right: 0;
          padding-bottom: 70px;
          border-right: 0;
          border-bottom: 1px solid $gray;
        }

        @include is-mobile {
          padding-bottom: 35px;
        }
      }
    }

    &-staff {
      width: 37.5%;

      @include is-tablet {
        width: 100%;
      }

      #{$this}__desc + & {
        margin-left: 70px;

        @include is-tablet {
          margin-left: 0;
          margin-top: 70px;
        }

        @include is-mobile {
          margin-top: 35px;
        }
      }

      &__list {
        &-item {
          display: flex;
          font-family: $font-en;

          &-ttl {
            width: 220px;
            padding-right: 40px;

            @include is-mobile {
              width: 160px;
              padding-right: 30px;
            }
          }

          &-desc {
            width: calc(100% - 220px);

            @include is-mobile {
              width: calc(100% - 160px);
            }
          }

          &-link {
            position: relative;

            &::after {
              content: "";
              position: absolute;
              right: 0;
              bottom: 0;
              left: 0;
              height: 1px;
              background-color: $black;
            }

            &.is-no-link {
              pointer-events: none;

              &::after {
                content: none;
              }
            }
          }
        }
      }
    }
  }

  &-contents {
    &__block {
      width: 60vw;
      border: 0;
      background-color: transparent;
      position: relative;
      transition: opacity .2s ease;
      margin: 0 auto;

      @include is-laptop {
        &:hover {
          &.is-movie {
            opacity: .8;
          }
        }
      }

      @include is-tablet {
        width: 80vw;
      }

      &:not(:last-child) {
        margin-bottom: 220px;

        @include is-mobile {
          margin-bottom: 90px;
        }
      }

      &.is-movie {
        cursor: pointer;

        &::before {
          content: "";
          background-image: url("../img/common/movie_ico.svg");
          background-position: center center;
          background-size: cover;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 60px;
          height: 60px;
          opacity: 1;
          transition: opacity .2s ease;
          z-index: 1;
        }
      }
    }

    &__figure {
      position: relative;
      width: 100%;
      padding-bottom: 75%;

      &-img {
        display: none;
      }

      &-bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }

  &__btn {
    text-align: left;
  }
}
