.l-main {
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  padding: 0 $side-padding;

  @include is-mobile {
    padding: 0 $side-padding-mobile;
  }
}
