@charset "UTF-8";

/*----------------------------------------
g-side
----------------------------------------*/
.g-side {
  position: fixed;
  bottom: 0;
  right: 30px;
  height: 55vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @include is-tablet {
    position: static;
    display: block;
    height: auto;
    margin-bottom: 50px;
  }

  &__list {

    @include is-tablet {
      display: flex;
      justify-content: center;
    }

    &-item {
      &-link {
        @include fz(16);
        padding: 10px;
        display: block;
        line-height: 1;
        transition: transform .2s ease;

        @include is-laptop {
          &:hover {
            transform: scale(1.5);
          }
        }

        @include is-tablet {
          @include fz(18);
        }
      }
    }
  }
}
