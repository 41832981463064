@charset "UTF-8";

/*----------------------------------------
front
----------------------------------------*/
.p-front {
  &-mv {
    padding: 0 100px;
    text-align: left;

    @include is-tablet {
      margin: 80px -#{$side-padding} 0 -#{$side-padding};
      padding: 0;
    }

    @include is-mobile {
      margin: 80px -#{$side-padding-mobile} 0 -#{$side-padding-mobile};
    }

    &__figure {
      &-img {
        width: 60vw;

        @include is-tablet {
          width: 100%;
        }
      }
    }
  }

  &-imageblock {
    padding-top: 280px;

    @include is-mobile {
      padding-top: 120px;
    }

    &__section {
      &:not(:last-child) {
        margin-bottom: 240px;

        @include is-mobile {
          margin-bottom: 120px;
        }
      }
    }

    &__list {
      &-item {
        $this: &;
        display: flex;

        &-link {
          display: block;
          position: relative;
          z-index: 0;
          opacity: 0;
          transition: opacity 1s ease, transform 1s ease;
          transform: translateY(50px);

          &.is-active {
            opacity: 1;
            transform: translateY(0);
          }


          &:hover {
            &::before {
              opacity: 1;
            }

            &::after {
              .is-movie & {
                opacity: 0;
              }
            }
          }

          &.is-no-link {
            pointer-events: none;
          }

          &::before {
            content: "";
            background: rgba($black, .5);
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            position: absolute;
            pointer-events: none;
            opacity: 0;
            transition: opacity .2s ease;
            z-index: 1;
          }

          &::after {
            .is-movie & {
              content: "";
              background-image: url("../img/common/movie_ico.svg");
              background-position: center center;
              background-size: cover;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
              width: 60px;
              height: 60px;
              opacity: 1;
              transition: opacity .2s ease;
              z-index: 1;
            }
          }

        }

        &-figure {
          &::before {
            content: "";
            background-image: url("../img/common/bracket_upper.svg");
            background-size: cover;
            width: 110px;
            height: 110px;
            position: absolute;
            top: -10px;
            left: -10px;
            opacity: 0;
            transition: opacity .2s ease;

            @include is-mobile {
              display: none;
            }

            #{$this}-link:hover & {
              opacity: 1;
            }
          }

          &::after {
            content: "";
            background-image: url("../img/common/bracket_lower.svg");
            background-size: cover;
            width: 110px;
            height: 110px;
            position: absolute;
            bottom: -10px;
            right: -10px;
            opacity: 0;
            transition: opacity .2s ease;

            @include is-mobile {
              display: none;
            }

            #{$this}-link:hover & {
              opacity: 1;
            }
          }
        }

        &:nth-child(1) {

          .a_block & {
            justify-content: flex-end;
            margin-right: 4.375vw;

            @include is-tablet {
              margin-right: 0;
            }
          }

          .b_block & {
            justify-content: flex-start;

            @include is-tablet {
              margin-left: -#{$side-padding};
            }

            @include is-mobile {
              margin-left: -#{$side-padding-mobile};
            }

            &:not(:last-child) {
              margin-bottom: 125px;

              @include is-mobile {
                margin-bottom: 80px;
              }
            }
          }

          .c_block & {
            justify-content: flex-end;
            margin-right: 15vw;

            @include is-tablet {
              margin-right: 0;
            }

            &:not(:last-child) {
              margin-bottom: 240px;

              @include is-tablet {
                margin-bottom: 120px;
              }
            }
          }
        }

        &:nth-child(1) &-link {
          .a_block & {
            width: 30vw;

            @include is-tablet {
              width: 64vw;
            }
          }

          .b_block & {
            width: 30vw;

            @include is-tablet {
              width: 64vw;
            }
          }

          .c_block & {
            width: 80vw;
          }
        }

        &:nth-child(2) {
          display: flex;

          .a_block & {
            justify-content: flex-start;
            margin-top: -180px;
            margin-left: 15.625vw;

            @include is-tablet {
              margin-top: 80px;
              margin-left: 6.25vw;
            }
          }

          .b_block & {
            justify-content: flex-end;

            @include is-tablet {
              margin: 0 -#{$side-padding};
            }

            @include is-mobile {
              margin: 0 -#{$side-padding-mobile};
            }

            &:not(:last-child) {
              margin-bottom: 125px;

              @include is-mobile {
                margin-bottom: 120px;
              }
            }
          }

          .c_block & {
            justify-content: flex-start;
            margin-left: 14.375vw;

            @include is-tablet {
              margin-left: 3.125vw;
            }

            &:not(:last-child) {
              margin-bottom: 80px;
            }

          }
        }

        &:nth-child(2) &-link {
          .a_block & {
            width: 20vw;

            @include is-tablet {
              width: 40vw;
            }
          }

          .b_block & {
            width: 60vw;

            @include is-tablet {
              width: 100%;
            }
          }

          .c_block & {
            width: 30vw;

            @include is-tablet {
              width: 64vw;
            }
          }
        }

        &:nth-child(3) {
          display: flex;

          .b_block & {
            justify-content: flex-start;
            margin-left: 5vw;

            @include is-tablet {
              margin-left: 0;
            }
          }

          .c_block & {
            justify-content: flex-end;

            @include is-tablet {
              margin-right: -#{$side-padding};

              &:not(:last-child) {
                margin-bottom: 80px;
              }
            }

            @include is-mobile {
              margin-right: -#{$side-padding-mobile};
            }
          }
        }

        &:nth-child(3) &-link {
          .b_block & {
            width: 30vw;

            @include is-tablet {
              width: 64vw;
            }
          }

          .c_block & {
            width: 40vw;

            @include is-tablet {
              width: 50vw;
            }
          }
        }

        &:nth-child(4) {
          justify-content: flex-start;
          margin-left: 5vw;

          @include is-pc-large {
            margin-left: 0;
          }

        }

        &:nth-child(4) &-link {
          .c_block & {
            width: 40vw;

            @include is-pc {
              width: 35vw;
            }

            @include is-tablet {
              width: 80vw;
            }
          }
        }

        &-desc {
          @include fz(16);
          color: $white;
          text-align: center;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          opacity: 0;
          transition: opacity .2s ease;
          padding: 20px;
          font-weight: $medium;
          z-index: 1;

          &::after {
            content: "";
            background-image: url("../img/common/arrow.svg");
            background-size: cover;
            width: 47px;
            height: 8px;
            display: block;
            margin-top: 30px;
          }

          #{$this}-link:hover & {
            opacity: 1;
          }
        }

        &-img {
          width: 100%;
        }

      }
    }
  }

  &-morebtn {
    margin-top: 170px;

    @include is-mobile {
      margin-top: 70px;
    }


  }
}
