@charset "UTF-8";

/*----------------------------------------
g-nav
----------------------------------------*/
.g-nav {
  @include is-tablet {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    width: 480px;
    background: $white;
    pointer-events: none;
    transform: translateX(100%);
    transition: transform .5s cubic-bezier(.72, .16, .27, 1.26);
    transform-origin: right;
    padding: 110px 20px 35px;
  }

  @include is-mobile {
    width: 240px;
  }

  [data-menu="active"] & {
    pointer-events: initial;
    transform: translateX(5%);
  }

  &__list {
    display: flex;
    align-items: center;
    height: 100%;
    font-family: $font-en;
    font-weight: $semibold;
    overflow-y: auto;

    @include is-tablet {
      height: auto;
      flex-direction: column;
      align-items: flex-start;
    }

    &-item {
      text-align: left;

      &:first-child {
        display: none;

        @include is-tablet {
          display: block;
        }
      }

      &-link {
        position: relative;
        padding: 20px;
        display: block;

        @include is-laptop {
          &:hover {
            &::before {
              opacity: 1;
            }
          }
        }

        @include is-mobile {
          @include fz(16);
        }

        &::before {
          content: "";
          position: absolute;
          height: 3px;
          right: 20px;
          left: 20px;
          bottom: 5px;
          background: $black;
          display: block;
          opacity: 0;
          transition: opacity .2s ease;
        }


      }
    }
  }

  &-sns {
    &__list {
      display: none;

      @include is-tablet {
        display: flex;
        justify-content: flex-start;
        margin-left: 10px;
      }

      &-item {
        &-link {
          @include fz(17);
          padding: 15px;
          display: block;
          line-height: 1;
          transition: transform .2s ease;
        }
      }
    }
  }
}
