.g-hamburger {
  display: none;
  position: relative;
  z-index: 100;
  border: 0;
  cursor: pointer;
  background: transparent;

  @include is-tablet {
    display: block;
  }

  &__line {
    background: $primary;
    width: 25px;
    height: 2px;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translateX(50%);
    transition: transform .2s ease;

    @include is-mobile {
      width: 20px;
    }

    &:first-child {
      margin: -3px 0 0;
    }

    &:last-child {
      margin: 3px 0 0;
    }

    &-container {
      display: block;
      position: relative;
      height: 35px;
      width: 35px;
    }

  }

  [data-menu="active"] & &__line {
    transform-origin: center center;

    &:first-child {
      margin: 0;
      transform: translateX(50%) rotate(135deg);
    }

    &:last-child {
      margin: 0;
      transform: translateX(50%) rotate(400deg);
    }

  }

}
