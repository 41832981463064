@charset "UTF-8";

/*----------------------------------------
c-works
----------------------------------------*/

.c-works {
  &__ttl {
    @include fz(18);
    text-align: left;
    margin-bottom: 60px;
    font-weight: 700;

    @include is-mobile {
      @include fz(14);
      margin-bottom: 30px;
    }
  }

  &-categories {
    margin-bottom: 100px;

    @include is-mobile {
      margin-bottom: 50px;
    }

    &__list {
      width: 100%;
      display: flex;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: $gray-dull;
      }

      &-item {
        &:first-child {
          margin-right: auto;
        }

        &:not(:first-child) {
          margin-left: 60px;
        }

        &.is-active &-link {
          &::after {
            opacity: 1;
          }
        }

        &-link {
          @include fz(16);
          font-family: $font-en;
          font-weight: $semibold;
          position: relative;
          padding: 13px 0;
          display: block;

          @include is-laptop {
            &:hover {
              &::after {
                opacity: 1;
              }
            }
          }

          @include is-mobile {
            @include fz(13);
            padding: 3px 0;
          }

          &::after {
            content: "";
            position: absolute;
            height: 3px;
            bottom: 0;
            left: 0;
            right: 0;
            background: $black;
            opacity: 0;
            transition: opacity .2s ease;
          }
        }
      }
    }
  }

  &__posts {
    &-list {
      display: flex;
      flex-wrap: wrap;


      &-item {
        width: calc(50% - 160px);
        margin: 80px;

        @include is-tablet {
          width: 100%;
          margin: 35px 0;
        }

        &-link {
          display: block;
          position: relative;
          transition: opacity .2s ease;

          @include is-laptop {
            &:hover {
              opacity: .8;
            }
          }
        }

        &-figure {
          position: relative;
          padding-bottom: 75%;
          width: 100%;

          &::after {
            .is-movie & {
              content: "";
              background-image: url("../img/common/movie_ico.svg");
              background-position: center center;
              background-size: cover;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
              width: 60px;
              height: 60px;
              opacity: 1;
              transition: opacity .2s ease;

              .is-taxonomy-photo & {
                content: none;
              }
            }
          }

          &-bg {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
          }
        }

        &-img {
          display: none;
        }

        &-ttl {
          @include fz(16);
          font-weight: $medium;
          margin-top: 25px;

          @include is-mobile {
            @include fz(13);
            margin-top: 15px;
          }
        }
      }
    }

    &-tag {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 5px -5px -5px;

      @include is-mobile {
        margin: 5px -5px 0;
      }

      &.is-single {
        justify-content: flex-start;
      }

      &-link {
        @extend %btn-underline;
        @include fz(12);
        display: inline-block;
        padding: 0 5px;

        @include is-laptop {
          &:hover {
            &::before {
              transform: scaleX(1);
            }
          }
        }

        @include is-mobile {
          @include fz(11);
        }

        &::before {
          left: 5px;
          right: 5px;
          bottom: 5px;
          transform: scale(0);
        }
      }
    }
  }

  &-more {
    margin-top: 160px;
  }
}
