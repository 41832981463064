@charset "UTF-8";

/*----------------------------------------
g-pagetop
----------------------------------------*/

.g-pagetop {
  margin-bottom: 40px;

  @include is-tablet {
    position: fixed;
    bottom: 0;
    right: 10px;
    margin-bottom: 15px;
  }

  &__btn {
    @include fz(12);
    font-family: $font-en;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    transition: opacity .2s ease;
    color: $black;

    @include is-mobile {
      @include fz(10);
    }

    @include is-laptop {
      &:hover {
        opacity: .6;
      }
    }

    &::before {
      content: "";
      background-image: url("../img/common/pagetop.svg");
      background-position: center center;
      background-size: cover;
      width: 37px;
      height: 15px;
      display: block;
      margin: 0 auto 5px;

      @include is-mobile {
        width: 30px;
        height: 13px;
      }
    }

    &-pc {
      @include is-tablet {
        display: none;
      }
    }
  }
}
