@charset "UTF-8";

/*----------------------------------------
c-btn
----------------------------------------*/

.c-btn {
  &__link {
    @extend %btn-underline;
    @include fz(16);
    font-family: $font-en;
    font-weight: $semibold;
    padding: 20px;

    @include is-mobile {
      @include fz(13);
    }

    &::before {
      height: 3px;
      bottom: 10px;
      right: 20px;
      left: 20px;

      @include is-mobile {
        bottom: 15px;
      }
    }
  }
}
