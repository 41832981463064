@charset "UTF-8";

/*----------------------------------------
p-404
----------------------------------------*/

.p-404 {
  text-align: left;

  &__ttl {
    @include fz(24);
    @include ls(1);
    margin-bottom: 20px;
    font-weight: $bold;

    @include is-mobile {
      margin-bottom: 10px;
    }
  }

  &__desc {
    @include fz(16);
    @include ls(1);
    margin-bottom: 100px;

    @include is-mobile {
      @include fz(12);
      margin-bottom: 50px;
    }
  }

  &__btn {
    margin-left: -20px;
  }
}
