
/*----------------------------------------
Extend
----------------------------------------*/
%img-hover-scale {
  text-align: left;

  @include is-laptop {
    &:hover {

      .c-img-hover-item {
        transform: scale(1.1);
      }

    }
  }
}

%img-clip {
  position: relative;
  overflow: hidden;
  z-index: 1;

  &::after {
    content: "";
    display: block;
    padding-bottom: 70%;
  }

  .c-img {
    display: none;
  }

  .c-img-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
  }
}

%btn-underline {
  position: relative;
  transition: color .2s ease;

  @include is-laptop {
    &:hover {
      color: $gray-dull;

      &::before {
        transform: scaleX(0);
        transform-origin: right;
      }
    }
  }

  &::before {
    content: "";
    height: 1px;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    background: $black;
    display: block;
    transform: scaleX(1);
    transform-origin: left;
    transition: transform .2s ease;
  }
}
