.l-content {
  &__main {
    padding: 0 $side-padding;

    @include is-mobile {
      padding: 0 $side-padding-mobile;
    }
  }

  &__container {
    max-width: calc(#{$outer-width + #{$side-padding * 2}});
    margin: 0 auto;

    &-s {
      max-width: calc(#{$content-width + #{$side-padding * 2}});
      margin: 0 auto;
    }
  }
}
